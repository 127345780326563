.glow-on-hover {
  width: 100%;
  height: 50px;
  border: none;
  outline: none;
  color: #fff;
  background: #111;
  cursor: pointer;
  position: relative;
  z-index: 0;
  border-radius: 10px;
}

.glow-on-hover:before {
  content: "";
  background: linear-gradient(
    45deg,
    #00008b,
    #0000cd,
    #0000ff,
    #1e90ff,
    #00bfff,
    #1e90ff,
    #0000ff,
    #0000cd,
    #00008b
  );
  position: absolute;
  top: -1px;
  left: -1px;
  background-size: 400%;
  z-index: -1;
  filter: blur(0px);
  width: calc(100% + 2px);
  height: calc(100% + 2px);
  animation: glowing 20s linear infinite;
  opacity: 1;
  transition: opacity 0.3s ease-in-out;
  border-radius: 10px;
}

.glow-on-hover:after {
  z-index: -1;
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background: #00094a00;
  background-image: linear-gradient(
    326deg,
    rgb(35, 14, 93) 17%,
    rgba(20, 20, 129, 1) 59%,
    rgb(47, 174, 248) 96%
  );
  left: 0;
  top: 0;
  border-radius: 10px;
}

@keyframes glowing {
  0% {
    background-position: 0 0;
  }
  50% {
    background-position: 400% 0;
  }
  100% {
    background-position: 0 0;
  }
}
