.carousel-container {
  overflow: hidden;
  position: relative;
  max-height: 600px;
  border-color: #4b5563;
  width: 100%;
}

.carousel-card {
  flex: 0 0 auto; /* Las imágenes no se redimensionan */
  width: 100%;
  max-width: 100px; /* Ajusta según tu diseño */
  height: 100%;
  object-fit: cover;
  border-radius: 0%;
  background: rgba(255, 255, 255, 0.489);
  transition: transform 0.3s, box-shadow 0.3s;
}
/*.carousel-card:hover {
  transform: scale(0.9);
  box-shadow: 5px 5px 8px rgba(222, 222, 222, 0.362), 10px 10px 8px rgba(152, 133, 133, 0.392),
    15px 15px 8px rgba(139, 154, 139, 0.303);
}*/

.carousel-card > img {
  height: 100%;
  width: 100%;
  min-width: 100px;
  border-radius: 0%;
}

.carousel-track {
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  gap: 16px;
  width: max-content;
  height: 100%;
}

@media (max-width: 640px) {
  .carousel-track {
    animation-duration: calc(4s * 8);
  }
}

/* Animación hacia la izquierda */
.carousel-left {
  left: 0;
  animation: slide-left linear infinite;
  animation-duration: calc(2s * 8);
}

/* Animación hacia la derecha */
.carousel-right {
  right: 0;
  animation: slide-right linear infinite;
  animation-duration: calc(2s * 8);
}

@keyframes slide-left {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-50%);
  }
}

@keyframes slide-right {
  from {
    transform: translateX(-50%);
  }
  to {
    transform: translateX(0%);
  }
}
